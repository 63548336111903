import React from 'react'

import { Button } from '@refera/ui-web'

// import { ROUTES } from '_/utils/constants'
// import { navigate, useParams } from '@reach/router'
import useRolePermission from '_/hooks/use-role-permission'

export default function ManageServicesButton() {
  const { isAdmin } = useRolePermission()
  // const { serviceOrderId } = useParams()

  // const handleButtonClick = useCallback(() => {
  //   return navigate(`${ROUTES.SERVICE_ORDER}/${serviceOrderId}/orcamento/${budgetId}/servicos`)
  // }, [navigate, serviceOrderId])

  if (!isAdmin) return null

  return (
    <Button onClick={() => {}} color="primary" variant="secondary">
      Gerenciar serviços
    </Button>
  )
}
