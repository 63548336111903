import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    color: 'white',
    border: 'none',
    padding: spacing(12, 16),
    background: palette.green.light,

    '&:hover': {
      background: palette.green.dark,
      border: 'none',
    },
  },
  buttonGap: {
    width: spacing(8),
  },
}))
