import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from '@reach/router'

import { Button } from '@refera/ui-web'

import { getBudgetByIdSelectorJS } from '_/modules/budget/selectors'
import { useBudgetForm } from '_/views/budget-view-v2/hooks/use-budget-form'

import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'

import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { CanDoButtonAction } from '_/utils/can-do-button-action'

export default function EditButton() {
  const { budgetId } = useParams()
  const { showToast } = useToast()
  const { isEditing, setIsEditing } = useBudgetForm()
  const { isTradesman, isAdminSAAS, checkUserPermission } = useRolePermission()
  const budget = useSelector(getBudgetByIdSelectorJS(budgetId))

  // Show button if is not original, budget already exists and is not editing
  const showButton = !budget?.isOriginal && budgetId && !isEditing
  const buttonAction = isTradesman
    ? BUTTONS_ACTION.EDIT_BUDGET_BY_PROVIDER
    : BUTTONS_ACTION.EDIT_BUDGET

  const canEditBudget = CanDoButtonAction({
    nameButton: buttonAction,
  })

  function toggleEdit() {
    if (!canEditBudget) {
      showToast({
        type: 'error',
        message: 'Este orçamento está em uma etapa que não permite edição.',
      })
      return
    }

    if (isAdminSAAS) {
      setIsEditing(!isEditing)
      return
    }

    checkUserPermission({
      permission: PERMISSIONS.BUDGETS,
      permissionAction: PERMISSIONS_ACTIONS.ADD,
      functionAccess: () => setIsEditing(!isEditing),
    })
  }

  if (!showButton) return null

  return (
    showButton && (
      <Button onClick={toggleEdit} color="primary" variant="secondary">
        Editar
      </Button>
    )
  )
}
