import React, { useCallback, useMemo } from 'react'

import { Button } from '@refera/ui-web'
import { useLocation } from '@reach/router'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { useToast } from '_/hooks/use-toast'
import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import { CanDoButtonAction } from '_/utils/can-do-button-action'

export default function SaveButton() {
  const { pathname } = useLocation()
  const { isAdmin, isSAAS, isAdminSAAS, checkIfUserDoesNotPermission } = useRolePermission()
  const { showToast } = useToast()

  const isCreating = useMemo(() => pathname.match(/\/novo$/), [])
  const showSaveButton = isCreating && (isAdmin || isSAAS)

  const canProviderOrSAASSendBudget = CanDoButtonAction({
    nameButton: BUTTONS_ACTION.PROVIDER_SEND_BUDGET,
  })

  const canReferaSendBudget = CanDoButtonAction({
    nameButton: BUTTONS_ACTION.SELECT_TRADESMAN,
  })

  const handleSave = useCallback(() => {
    let canSave = true
    let errorMessage = 'Você não tem permissão para esta ação.'

    if (isCreating) {
      // validate by button action
      const invalidStep =
        (isSAAS && !canProviderOrSAASSendBudget) || (isAdmin && !canReferaSendBudget)

      if (invalidStep) {
        canSave = false
        errorMessage = 'Este chamado está em uma etapa que não permite incluir orçamento.'
      }

      // validate by permission
      if (!isAdminSAAS) {
        const unauthorized = checkIfUserDoesNotPermission(
          PERMISSIONS.ORIGINAL_BUDGETS,
          PERMISSIONS_ACTIONS.ADD
        )

        // if unauthorized, shows toast and return
        if (unauthorized) {
          return
        }
      }
    }

    if (canSave)
      showToast({
        message: 'Ação permitida',
        type: 'success',
      })
    else
      showToast({
        message: errorMessage,
        type: 'error',
      })
  }, [canProviderOrSAASSendBudget, canReferaSendBudget, isAdminSAAS, isCreating])

  return (
    showSaveButton && (
      <Button onClick={handleSave} color="primary" variant="primary">
        Salvar
      </Button>
    )
  )
}
