/* eslint-disable import/no-cycle */
import React, { createContext, useState, useContext, useMemo } from 'react'

const BudgetFormContext = createContext({})

function BudgetFormProvider({ children }) {
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const contextData = useMemo(
    () => ({
      loading,
      setLoading,
      isEditing,
      setIsEditing,
    }),
    [loading, isEditing, setIsEditing, setLoading]
  )

  return <BudgetFormContext.Provider value={contextData}>{children}</BudgetFormContext.Provider>
}

/**
 * @returns {{loading: boolean, setLoading: Function, isEditing: boolean, setIsEditing: Function}} Contexto do formulário de orçamento
 */

function useBudgetForm() {
  const context = useContext(BudgetFormContext)

  if (!context) throw new Error('useBudgetForm must be used within a BudgetFormProvider')
  return context
}

export { BudgetFormProvider, useBudgetForm }
