import React, { useMemo } from 'react'

import { Button } from '@refera/ui-web'

import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import useRolePermission from '_/hooks/use-role-permission'
import { CanDoButtonAction } from '_/utils/can-do-button-action'
// import { useDialog } from '_/hooks/use-dialog'
// import { getBudgetTemplates } from '_/modules/budgetTemplate/actions'
// import { getProvidersOrders } from '_/modules/provider/actions'
// import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
// import ImportBudgetTemplateForm from '_components/budget/navbar/import-budget-model-button/import-budget-template-form'
// import { userSelector } from '_/modules/authentication/selectors'
// import { useSelector } from 'react-redux'
// import { useDispatch } from 'react-redux'
// import { useParams } from '@reach/router'

export default function ImportBudgetButton() {
  // const [isImportModelModalOpen, setIsImportModelModalOpen] = useState(false)
  // const [serviceOrdersId, setServiceOrdersId] = useState()

  // const { showDialog, closeDialog } = useDialog()
  // const { budgetId } = useParams()

  // const dispatch = useDispatch()

  // const user = useSelector(userSelector)
  // const serviceOrder = useSelector(currentServiceOrderSelector)
  // const budgetTemplatesSelector = useSelector(budgetTemplateSelector)

  const { isTradesman } = useRolePermission()

  const canImportBudgetTemplate = CanDoButtonAction({
    nameButton: BUTTONS_ACTION.IMPORT_BUDGET_TEMPLATE,
    isBudgetRelated: true,
  })

  const isButtonVisible = useMemo(
    () => canImportBudgetTemplate && isTradesman && true, // true is a replacement for isEditing
    [canImportBudgetTemplate, isTradesman]
  )

  // const handleImportReject = useCallback(() => {
  //   showDialog({
  //     type: 'warning',
  //     subject: 'Este chamado encontra-se em uma Etapa que não permite mais esta ação.',
  //     labelApprove: 'Ok',
  //     onApprove: closeDialog,
  //   })
  // }, [])

  // const handleSave = useCallback(() => {
  // if (canImportBudgetTemplate && serviceOrder?.stepStatus === STEP_STATUS.WAITING_BUDGET) {
  //   setIsImportModelModalOpen(true)
  // } else {
  //   handleImportReject()
  // }
  // }, [canImportBudgetTemplate])

  const handleOnShowConfirmation = async () => {
    // dispatch(getBudgetTemplates())
    // await dispatch(getProvidersOrders({ provider_id: user.getServiceProviderId })).then(res => {
    //   setServiceOrdersId(res)
    // })
    // showDialog({
    //   type: 'warning',
    //   subject: 'Você tem certeza que deseja executar esta ação?',
    //   labelApprove: 'Sim',
    //   labelCancel: 'Não',
    //   onApprove: () => {
    //     handleSave()
    //     closeDialog()
    //   },
    //   onCancel: closeDialog,
    // })
  }

  if (!isButtonVisible) return null

  return (
    <>
      <Button
        onClick={handleOnShowConfirmation}
        color="primary"
        variant="secondary"
        // disabled={isLoading}
      >
        Importar orçamento
      </Button>
      {/* {isImportModelModalOpen && budgetTemplatesSelector && (
        <ImportBudgetTemplateForm
          closeModal={() => setIsImportModelModalOpen(false)}
          budget={budgetId}
          budgetTemplates={budgetTemplatesSelector}
          serviceOrdersId={serviceOrdersId}
        />
      )} */}
    </>
  )
}
