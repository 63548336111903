import React, { useCallback, useMemo } from 'react'

import { useLocation, navigate } from '@reach/router'

import { Button } from '@refera/ui-web'
import { useBudgetForm } from '_/views/budget-view-v2/hooks/use-budget-form'

export default function CancelButton() {
  // const { isEditing, toggleIsEditing } = useContext(BudgetContext)
  const { pathname } = useLocation()
  const { isEditing, setIsEditing } = useBudgetForm()
  const isCreating = useMemo(() => pathname.match(/\/novo$/), [])
  const showButton = isEditing || isCreating

  // const {
  //   formState: { defaultValues },
  //   reset,
  // } = useFormContext()

  const handleCancel = useCallback(() => {
    if (isCreating) return navigate(-1)
    // reset(defaultValues)
    // toggleIsEditing()
    setIsEditing(false)
    return null
  }, [isCreating])

  if (!showButton) return null

  return (
    <Button onClick={handleCancel} color="red" variant="secondary">
      Cancelar
    </Button>
  )
}
