import { makeStyles } from '@material-ui/styles'

import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  container: {
    height: 72,
    width: '100%',
    display: 'flex',
    gap: spacing(24),
    padding: spacing(16, '2%', 16, 46),
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,

    [breakpoints.down('md')]: {
      height: 92,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    minWidth: spacing(92),
    justifyContent: 'center',
  },
  deadline: {
    marginTop: spacing(-4),
  },
  label: {
    color: 'black',
    fontSize: Theme.Typography.FontSize.XXSmall,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: Theme.Typography.FontWeight.Medium,
  },
  priority: {
    transform: 'scale(1.25)',
    transformOrigin: 'top left',
    marginTop: spacing(-3),
    marginBottom: spacing(3),
  },
  category: {
    gap: spacing(8),
    display: 'flex',
  },
  itemText: {
    fontSize: spacing(15),
    lineHeight: spacing(20),
  },
  itemContent: {
    minHeight: spacing(20),
    display: 'flex',
    alignItems: 'center',
  },
  rightContent: {
    marginLeft: 'auto',
  },
  contactButton: {
    color: 'white',
    border: 'none',
    padding: spacing(12, 16),
    background: palette.green.light,

    '&:hover': {
      background: palette.green.dark,
      border: 'none',
    },
  },
  buttonGap: {
    width: spacing(8),
  },
}))
