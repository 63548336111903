import React, { useCallback, useMemo } from 'react'

import { Button } from '@refera/ui-web'
import { useLocation } from '@reach/router'
import useRolePermission from '_/hooks/use-role-permission'

export default function SendButton() {
  const { pathname } = useLocation()
  const { isTradesman } = useRolePermission()

  const isCreating = useMemo(() => pathname.match(/\/novo$/), [])
  const showSaveButton = isTradesman && !isCreating

  const handleSave = useCallback(() => {}, [])

  return (
    showSaveButton && (
      <Button onClick={handleSave} color="primary" variant="primary">
        Enviar
      </Button>
    )
  )
}
