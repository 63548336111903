import React from 'react'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'
import {
  CancelButton,
  EditButton,
  ExecutionDataButton,
  ImportBudgetButton,
  ManageServicesButton,
  NFSEButton,
  SaveButton,
  SaveModelButton,
  SendButton,
  TemporarySaveButton,
  ViewInstallmentsButton,
} from './buttons'

import { useParams } from '@reach/router'
import { useSelector } from 'react-redux'
import { getBudgetByIdSelectorJS } from '_/modules/budget/selectors'

export function Header() {
  const styles = useStyles()
  const { budgetId } = useParams()
  const budget = useSelector(getBudgetByIdSelectorJS(budgetId))

  const renderTitle = () => {
    if (budgetId) {
      const title = `Orçamento n.º ${budgetId}`
      return budget?.isOriginal ? `${title} - Original` : title
    }

    return 'Novo Orçamento'
  }

  return (
    <HeaderTitle title={renderTitle()} backButtonAction={() => {}}>
      <div className={styles.headerButtonsContainer}>
        <SaveModelButton />
        <ExecutionDataButton />
        <NFSEButton />
        <ViewInstallmentsButton />
        <ManageServicesButton />
        <ImportBudgetButton />
        <EditButton />
        <CancelButton />
        <TemporarySaveButton />
        <SaveButton />
        <SendButton />
      </div>
    </HeaderTitle>
  )
}
