import React, { useMemo } from 'react'

import { Button } from '@refera/ui-web'

import useRolePermission from '_/hooks/use-role-permission'
import { useLocation } from '@reach/router'

export default function SaveModelButton() {
  const { isTradesman } = useRolePermission()
  const { pathname } = useLocation()
  const isCreating = useMemo(() => pathname.match(/\/novo$/), [])

  const showButton = isTradesman && !isCreating

  function handleClick() {}

  if (!showButton) return null

  return (
    <Button onClick={handleClick} color="primary" variant="secondary">
      Salvar modelo
    </Button>
  )
}
