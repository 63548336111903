import React from 'react'
import { navigate, useLocation, useParams } from '@reach/router'

import { Button } from '@refera/ui-web'

import useRolePermission from '_/hooks/use-role-permission'

export default function ExecutionDataButton() {
  const { budgetId, serviceOrderId } = useParams()
  const { pathname } = useLocation()
  const { isAdmin, isSAAS, isTradesman } = useRolePermission()

  const showButton = (isAdmin || isSAAS || isTradesman) && budgetId

  function handleClick() {
    navigate(`/chamado/${serviceOrderId}/orcamento/${budgetId}/execucao`, {
      state: { from: pathname },
    })
  }

  if (!showButton) return null

  return (
    <Button onClick={handleClick} color="primary" variant="secondary">
      Dados da execução
    </Button>
  )
}
