import React from 'react'
import { WhatsApp } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'

import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'

import { numbersValid } from '_/utils/helpers'
import { openWhatsApp } from '_/utils/open-whatsApp'

import Button from '_components/button'

import useStyles from './styles'

export function ContactButton() {
  const styles = useStyles()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const { isTradesman } = useRolePermission()

  const { showToast } = useToast()

  if (!isTradesman) return null

  function handleClick() {
    if (!serviceOrder?.contactName) {
      return showToast({
        type: 'error',
        message: 'Chamado sem solicitante definido.',
      })
    }

    if (!serviceOrder?.contactNumber || !numbersValid(serviceOrder?.contactNumber)) {
      return showToast({
        type: 'error',
        message: 'Solicitante sem número de contato ou com número inválido.',
      })
    }

    return openWhatsApp(serviceOrder?.contactNumber)
  }

  return (
    <Button
      className={styles.container}
      onClick={handleClick}
      color="primary"
      variant="outlined"
      startIcon={<WhatsApp className={styles.iconWhatsApp} />}
    >
      <div className={styles.buttonGap} />
      <Typography>Solicitante</Typography>
    </Button>
  )
}
