import React, { useCallback, useEffect, useState } from 'react'

import { Loader } from '@refera/ui-web'

import { useDispatch, useSelector } from 'react-redux'
import { navigate, useLocation, useParams } from '@reach/router'

import { Header } from './components/Header'
// import { userSelector } from '_/modules/authentication/selectors'
import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'
import { BudgetFormProvider, useBudgetForm } from './hooks/use-budget-form'

import { getButtonsAction, getServiceOrder } from '_/modules/service-orders/actions'
import { getBudgetById, getStepStatusLog } from '_/modules/budget/actions'
import {
  currentServiceOrderSelector,
  getButtonsActionSelector,
} from '_/modules/service-orders/selectors'
import {
  // getBudgetByIdSelectorJS,
  getStepStatusLogSelector,
} from '_/modules/budget/selectors'

import { InfoBar } from './components/Header/info-bar'
import { classificationsServiceOrderSelector } from '_/modules/classification/selectors'
import { getClassification } from '_/modules/classification/actions'
// import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

// import useStyles from './styles'

function BudgetViewV2Screen() {
  // const styles = useStyles()
  const { pathname } = useLocation()
  const { serviceOrderId, budgetId } = useParams()

  const { loading, setLoading } = useBudgetForm()

  // const budget = useSelector(getBudgetByIdSelectorJS(budgetId))
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const classifications = useSelector(classificationsServiceOrderSelector)
  const buttonsActions = useSelector(getButtonsActionSelector)

  const [firstLoad, setFirstLoad] = useState(true)

  const dispatch = useDispatch()

  const { isTradesman, isSAAS, isAdmin } = useRolePermission()
  const { showToast } = useToast()

  const onPageLoad = useCallback(async () => {
    setLoading(true)
    setFirstLoad(true)
    try {
      const parsedServiceOrderId = parseInt(serviceOrderId, 10)

      // check if serviceOrderId is a valid number
      if (isNaN(parsedServiceOrderId)) {
        throw new Error(`Invalid serviceOrderId ${serviceOrderId}`, {
          cause: 'invalid_serviceorder_id',
        })
      }

      const isCreating = pathname.match(/\/novo$/)

      if (isCreating && !isTradesman && !isSAAS && !isAdmin) {
        showToast({
          message: 'Você não tem permissão para esta ação.',
          type: 'error',
        })
        navigate('/link-expirado')
        return
      }

      // check if creating or viewing/editing
      if (!isCreating && isNaN(parseInt(budgetId, 10))) {
        throw new Error(`Invalid budgetId ${budgetId}`, {
          cause: 'invalid_budget_id',
        })
      }

      // start with what's stored
      let currentServiceOrder = serviceOrder ? serviceOrder?.toJS() : null

      // if serviceOrder is not available because the url was forced, fetch it
      if (!serviceOrder) {
        currentServiceOrder = await dispatch(getServiceOrder(serviceOrderId))
        !stepStatusLog?.length && (await dispatch(getStepStatusLog(serviceOrderId, undefined)))
      }

      if (isCreating && currentServiceOrder.activeBudget) {
        showToast({
          message:
            'Este chamado possui um Orçamento ativo ou finalizado. Você deve recusar todos os orçamentos de tal chamado para executar esta ação.',
          type: 'error',
        })
        navigate('/')
        return
      }

      // if budgetId is available, fetch it
      if (budgetId) {
        const params = { budgetView: true, showOriginalBudget: false }
        await dispatch(getBudgetById(budgetId, params))
      }
    } catch (error) {
      if (error.cause === 'invalid_serviceorder_id' || error.cause === 'invalid_budget_id') {
        process.env.NODE_ENV === 'development' && console.error('parametros de URL invalidos')
      }

      navigate('/link-expirado')
      return
    }

    if (!buttonsActions?.isEmpty()) {
      dispatch(getButtonsAction())
    }

    if (classifications?.isEmpty()) {
      dispatch(getClassification({ active: 'True', serviceOrder: serviceOrderId }))
    }

    setLoading(false)
    setFirstLoad(false)
  }, [loading, firstLoad, buttonsActions, stepStatusLog, serviceOrderId, budgetId, classifications])

  useEffect(() => {
    onPageLoad()
  }, [])

  if (firstLoad) return <Loader hasBackdrop open={firstLoad} />

  return (
    <main>
      <Header />
      <InfoBar />
      <Loader hasBackdrop open={loading} />
    </main>
  )
}

export function BudgetViewV2() {
  return (
    <BudgetFormProvider>
      <BudgetViewV2Screen />
    </BudgetFormProvider>
  )
}
