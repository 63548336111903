import React, { useCallback, useMemo } from 'react'

import { Button } from '@refera/ui-web'

import useRolePermission from '_/hooks/use-role-permission'
import { CanDoButtonAction } from '_/utils/can-do-button-action'
import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import { useToast } from '_/hooks/use-toast'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
// import useCanDoButtonAction from '_/hooks/use-can-do-button-action'
// import { BUTTONS_ACTION } from '_/utils/constants/service-order'

export default function TemporarySaveButton() {
  const { isAdminSAAS, isTradesman, checkIfUserDoesNotPermission } = useRolePermission()
  const { showToast } = useToast()

  const canTemporarySave = CanDoButtonAction({
    nameButton: BUTTONS_ACTION.TEMPORARY_SAVE,
  })

  const showButton = useMemo(
    () => isTradesman && true, // true is a replacement for isEditing
    [canTemporarySave, isTradesman]
  )

  const handleTemporarySave = useCallback(() => {
    if (!canTemporarySave) {
      showToast({
        message: 'Este chamado está em uma etapa que não permite incluir orçamento.',
        type: 'error',
      })
      return
    }

    // validate by permission
    if (!isAdminSAAS) {
      const unauthorized = checkIfUserDoesNotPermission(
        PERMISSIONS.ORIGINAL_BUDGETS,
        PERMISSIONS_ACTIONS.ADD
      )

      // if unauthorized, shows toast and return
      if (unauthorized) {
        return
      }
    }

    showToast({
      message: 'Ação permitida',
      type: 'success',
    })
  }, [canTemporarySave, isAdminSAAS])

  if (!showButton) return null

  // change disabled false to isLoading later
  return (
    <Button onClick={handleTemporarySave} color="primary" variant="secondary" disabled={false}>
      Salvar temporário
    </Button>
  )
}
